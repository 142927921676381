/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 119번째 로그에서는 mysetting.io, Google I/O, AWS App Runner에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "mysetting.io"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.patreon.com/subicura"
  }, "subicura 님 Patreon 후원")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://vercel.com/"
  }, "Develop. Preview. Ship. For the best frontend teams – Vercel")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://supabase.io/"
  }, "The Open Source Firebase Alternative | Supabase")), "\n"), "\n", React.createElement(_components.h3, null, "Revue"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.getrevue.co/"
  }, "Revue - An editorial newsletter tool for writers and publishers.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://newneek.co/"
  }, "뉴닉 NEWNEEK - NEWNEEK")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.morningbrew.com/daily"
  }, "Morning Brew")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.feedly.com/get-newsletters-in-feedly/"
  }, "Get newsletters in Feedly – Feedly Blog - Feedly Blog")), "\n"), "\n", React.createElement(_components.h3, null, "Google I/O"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.google/technology/research/project-starline/"
  }, "Project Starline: Feel like you're there, together - Google")), "\n"), "\n", React.createElement(_components.h3, null, "AWS App Runner"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/blogs/containers/introducing-aws-app-runner/"
  }, "Introducing AWS App Runner | Amazon Web Services")), "\n"), "\n", React.createElement(_components.h3, null, "1Password for Linux"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.1password.com/welcoming-linux-to-the-1password-family/"
  }, "Welcoming Linux to the 1Password Family | 1Password Blog")), "\n"), "\n", React.createElement(_components.h3, null, "MS 에서 IE를 버리기로 했음"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2021/5/19/22443997/microsoft-internet-explorer-end-of-support-date"
  }, "Microsoft is finally retiring Internet Explorer in 2022 - The Verge")), "\n"), "\n", React.createElement(_components.h3, null, "Flat Data by GitHub OCTO"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://octo.github.com/projects/flat-data"
  }, "GitHub OCTO | Flat Data - GitHub OCTO")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
